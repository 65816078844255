<template>
  <div>
    <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Lorem ipsum dolor sit amet<br>
      Quia tempore, velit nisi vitae atque a neque ut laborum.
    </p>
    <InvoiceTable
      :headers="tableHeaders"
      :data="tableData"
    />
  </div>
</template>

<script src="./Invoices.controller.js"></script>
<style lang="scss" src="./Invoices.styles.scss"></style>
