import { InvoiceTable } from "@/components";
export default {
  name: "Invoices",
  components: {
    InvoiceTable
  },
  data: () => ({
    tableHeaders: [
      {
        name: "Factuurnummer"
      },
      {
        name: "Datum"
      },
      {
        name: "Omschrijving"
      },
      {
        name: "Totaal (excl. btw)"
      },
      {
        name: ""
      }
    ],
    tableData: [
      {
        invoiceNr: "202101695",
        date: Date(),
        description: "5 tokens",
        total: 60.0
      },
      {
        invoiceNr: "202101696",
        date: Date(),
        description: "3 tokens",
        total: 36.0
      },
      {
        invoiceNr: "202101697",
        date: Date(),
        description: "1 token",
        total: 12.0
      }
    ]
  })
};
